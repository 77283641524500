import { eventListenerOptions } from '@spinnwerk/polyfills';

let handlerOptions = eventListenerOptions({ passive: true, once: true });

export function showFocusOnTabKey() {
    // show focus style only after a tab keystroke
    function handleFirstTab(event) {
        if (event.keyCode === 9) {
            document.body.classList.add('user-is-tabbing');

            if (typeof handlerOptions !== 'object') {
                window.removeEventListener('keydown', handleFirstTab, handlerOptions);
            }
        }
    }

    window.addEventListener('keydown', handleFirstTab, handlerOptions);
}
